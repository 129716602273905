'use client'

import { useState, useEffect } from 'react'
import { Grid2 as Grid } from '@mui/material'

// Types
import type { BackgroundAtomProps } from '@/types/components/atoms/background'

const BackgroundAtom = (props: BackgroundAtomProps) => {
	// Props
	const { alt, width, height, image } = props
	// Variables
	const [loaded, setLoaded] = useState<boolean>(false)

	// Callbacks
	useEffect(() => {
		setLoaded(true)
	}, [setLoaded])

	return (
		<Grid
			width="100dvw"
			height="100dvh"
			position="absolute"
			top={0}
			left={0}
			zIndex={-1}
			sx={{
				'& picture': {
					width: 1,
					height: 1,
					'& img': {
						width: 1,
						height: 1,
						objectFit: 'cover',
						objectPosition: 'center'
					}
				}
			}}
		>
			{loaded ? (
				<picture>
					<img src={`/images/background/${image}`} alt={alt} width={width} height={height} />
				</picture>
			) : null}
		</Grid>
	)
}

export default BackgroundAtom
